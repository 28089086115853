import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

import { IHelp, IOrderSummary } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/../../common';
import { IGroup, IGroupDelivery, IGroups } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/../../common/get-groups';
import { IOrderInformation } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/../../common/get-order-information';
import { ISalesLine } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/../../common/get-sales-line';
import { IOrderConfirmationViewProps } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/./order-confirmation';

export const OrderConfirmationOrderInfomation: React.FC<IOrderInformation> = ({
    orderInformationProps,
    receiptEmail,
    createdDate,
    channelReferenceId
}) => (
    <Node {...orderInformationProps}>
        {createdDate}
        {channelReferenceId}
        {receiptEmail}
    </Node>
);

export const OrderConfirmationSalesLine: React.FC<ISalesLine> = ({ salesLineProps, salesLine/*, buyAgainButton*/ }) => (
    <Node {...salesLineProps}>{salesLine}</Node>
);

export const OrderConfirmationGroupDelivery: React.FC<IGroupDelivery> = ({
    deliveryProps,
    heading,
    //count,
    //processing,
    address,
    //trackingInfo,
    pickupDateTimeslot
}) => (
    <Node {...deliveryProps}>
        {heading}
        {address}
        {pickupDateTimeslot}
    </Node>
);

export const OrderConfirmationGroup: React.FC<IGroup> = ({ groupProps, delivery, salesLinesProps, salesLines }) => (
    <Node {...groupProps}>
        {delivery && <OrderConfirmationGroupDelivery {...delivery} />}
        {delivery && delivery.shippingItemsToYou}
        {salesLines && (
            <Node {...salesLinesProps}>
                {salesLines.map((salesLine: ISalesLine) => (
                    <OrderConfirmationSalesLine key={salesLine.data.salesLine.LineId} {...salesLine} />
                ))}
            </Node>
        )}
    </Node>
);

export const OrderConfirmationGroups: React.FC<IGroups> = ({ groupsProps, groups }) => (
    <Node {...groupsProps}>
        {groups.map((group: IGroup, index: number) => (
            <OrderConfirmationGroup key={index} {...group} />
        ))}
    </Node>
);

export const OrderConfirmationOrderSummary: React.FC<IOrderSummary> = ({
    orderSummaryProps,
    heading,
    subtotal,
    shipping,
    tax,
    totalAmount,
    earnedPoints
}) => (
    <Node {...orderSummaryProps}>
        {heading}
        {subtotal}
        {shipping}
        {tax}
        {totalAmount}
        {earnedPoints}
    </Node>
);

//ut-ext extension to add coupons info on order confirmation page
export const OrderConfirmationPayment: React.FC<IOrderConfirmationViewProps> = (props) => {

    const { paymentMethodsProps, title, methods } = props.payment!;

    const couponsNode = React.useMemo(() => {
        let couponCodes: string[] = [];
        props.salesOrder?.SalesLines?.forEach(sl => {
            if (sl.IsVoided || sl.IsReturnByReceipt || !((sl.DiscountAmount || 0) > 0)) return;
    
            sl?.DiscountLines?.forEach(dl => {
                if(dl.DiscountCode && couponCodes.indexOf(dl.DiscountCode!) === -1)
                    couponCodes.push(dl.DiscountCode!)
            });
        })
        
        const coupons = couponCodes.length > 0 ? (
            <div className="ms-order-confirmation__payment-methods-line">
               <p className="trus-order-confirmation__payment-methods-coupon">{couponCodes.join(",")}</p>
           </div>
       ) : null;

       return coupons;
    }, [props.salesOrder])

    return (
        <Node {...paymentMethodsProps}>
            {title}
            {methods}
            {couponsNode}
        </Node>
    )
}

export const OrderConfirmationHelp: React.FC<IHelp> = ({ helpProps, needHelpLabel, helpLineNumberLabel, contactNumber }) => (
    <Node {...helpProps}>
        {needHelpLabel}
        {helpLineNumberLabel}
        {contactNumber}
    </Node>
);

const OrderConfirmationView: React.FC<IOrderConfirmationViewProps> = (props) => {
    const {
        moduleProps,
        heading,
        backToShoppingLink,
        alert,
        loading,
        orderInfomation,
        orderSummary,
        payment,
        help,
        groups
    } = props;

    return (
        <Module {...moduleProps}>
            {heading}
            {alert}
            {loading}
            {orderInfomation && <OrderConfirmationOrderInfomation {...orderInfomation} />}
            {backToShoppingLink}
            {groups && <OrderConfirmationGroups {...groups} />}
            {payment && <OrderConfirmationPayment { ...props } />}
            {orderSummary && <OrderConfirmationOrderSummary {...orderSummary} />}
            {help && <OrderConfirmationHelp {...help} />}
        </Module>
    );
};

export default OrderConfirmationView;
